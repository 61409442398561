import { z } from 'zod';

import validationLang from '../../lang';
import { zodEnumFromObjKeys } from '../../zodEnumFromObjKeys';
import { NOTES_MAX_LENGTH } from '../constants';

const internalNotesValidation = z
    .string()
    .trim()
    .min(1, validationLang.REQUIRED)
    .max(NOTES_MAX_LENGTH, validationLang.GET_MAX_LENGTH(NOTES_MAX_LENGTH));

export const internalNotesSchema = z.object({
    notesInternal: internalNotesValidation,
});

export const internalNotesSchemaKeys = zodEnumFromObjKeys(internalNotesSchema.shape).Enum;
