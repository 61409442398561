export default {
    A2A_STATUS: 'Select a2a status',
    ACCOUNT_STATUS: 'Select account status',
    AUTH_CONTROL_TYPE: 'Select correct Authorization type',
    CARD_STATUS: 'Select card status',
    CARD_VC_WINDOW_KIND: 'Select correct frequency type',
    EFFECTIVE_FROM_EMPTY_MESSAGE: 'This date should be after effective from',
    EFFECTIVE_UNTIL_MESSAGE: "This date can't be earlier than effective from.",
    GET_LENGTH: (length: number) => `Length should be exact ${length} characters`,
    GET_MAX_LENGTH: (length: number) => `Max length is ${length} characters`,
    GET_MIN_LENGTH: (length: number) => `Minimum length is ${length} characters`,
    GREATER_THAN_0_MESSAGE: 'Minimal value must be equal or higher than 1.',
    INVALID_BIRTHDAY_MAX_DATE:
        'You must be at least 18 years old to register. Please check your date of birth and try again',
    INVALID_BIRTHDAY_MIN_DATE:
        'The date of birth you entered seems to be incorrect. Please check and enter a valid date.',
    INVALID_EMAIL: 'Please enter your email address.',
    INVALID_OTP_CODE: 'Wrong code. Try again.',
    INVALID_REGEX: `Only 0-9, a-z, A-Z, - and _ (example: external_reference-1234)`,
    REFUND: 'Select correct refund type',
    REQUIRED: 'Field is required',
    SELECT_CORRECT_CATEGORY: 'Select correct category',
    SELECT_POLICY: 'Select user policy for active sessions',
    SELECT_USER: 'Select user status',
    UTF8: 'Special symbols and numbers are not allowed.',
};
