import { isAfter } from 'date-fns';
import { z } from 'zod';

import {
    CardsVelocityControlScope,
    CardsVelocityControlTargetKind,
    CardsVelocityControlWindowKind,
} from '@dock/types-dock-partner';

import { requiredUuidValidation } from '../../common';
import validationLang from '../../lang';
import { zodEnumFromObjKeys } from '../../zodEnumFromObjKeys';

const nameValidation = z.string().trim();

export const createCardVCBasicDetailsStep = z.object({
    label: nameValidation,
    scope: z.nativeEnum(CardsVelocityControlScope),
    targetId: requiredUuidValidation,
    targetKind: z.nativeEnum(CardsVelocityControlTargetKind, {
        errorMap: () => ({ message: validationLang.SELECT_CORRECT_CATEGORY }),
    }),
    tenantId: requiredUuidValidation,
});

export const createCardVCBasicDetailsStepKeys = zodEnumFromObjKeys(
    createCardVCBasicDetailsStep.shape
).Enum;

export const createCardVCConfigurationStep = z.object({
    amount: z.string().trim().min(1, validationLang.REQUIRED),
    currency: z.string().trim(),
    effectiveFrom: z.date().nullable(),
    effectiveUntil: z.date().nullable(),
    windowDays: z.string().nullable(),
    windowKind: z.nativeEnum(CardsVelocityControlWindowKind, {
        errorMap: () => ({ message: validationLang.CARD_VC_WINDOW_KIND }),
    }),
    windowMonths: z.string().nullable(),
});

export const createCardVCConfigurationStepKeys = zodEnumFromObjKeys(
    createCardVCConfigurationStep.shape
).Enum;

const isUntilAfterFrom = (effectiveUntil: Date, effectiveFrom: Date): boolean =>
    !!effectiveUntil && !!effectiveFrom && isAfter(effectiveUntil, effectiveFrom);

export const createCardVCConfigurationStepRefinedSchema = createCardVCConfigurationStep
    .refine(
        (data) => {
            if (!data.effectiveFrom || !data.effectiveUntil) {
                return true;
            }

            return isUntilAfterFrom(data.effectiveUntil, data.effectiveFrom);
        },
        {
            message: validationLang.EFFECTIVE_UNTIL_MESSAGE,
            path: [createCardVCConfigurationStepKeys.effectiveUntil],
        }
    )
    .refine((data) => data.windowMonths !== '0', {
        message: validationLang.GREATER_THAN_0_MESSAGE,
        path: [createCardVCConfigurationStepKeys.windowMonths],
    })
    .refine((data) => data.windowDays !== '0', {
        message: validationLang.GREATER_THAN_0_MESSAGE,
        path: [createCardVCConfigurationStepKeys.windowDays],
    })
    .refine(
        (data) =>
            !(
                data.windowKind === CardsVelocityControlWindowKind.MONTHS &&
                data.windowMonths === ''
            ),
        {
            message: validationLang.REQUIRED,
            path: [createCardVCConfigurationStepKeys.windowMonths],
        }
    )
    .refine(
        (data) =>
            !(data.windowKind === CardsVelocityControlWindowKind.DAYS && data.windowDays === ''),
        {
            message: validationLang.REQUIRED,
            path: [createCardVCConfigurationStepKeys.windowDays],
        }
    )
    .refine(
        (data) => {
            if (
                data.effectiveFrom === null &&
                data.effectiveUntil &&
                data.effectiveUntil <= new Date()
            ) {
                return false;
            }

            return true;
        },
        {
            message: validationLang.EFFECTIVE_FROM_EMPTY_MESSAGE,
            path: [createCardVCConfigurationStepKeys.effectiveUntil],
        }
    );
